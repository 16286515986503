import React from "react"

export default function HdmMaus(props) {
  return (
    <svg
      id="fc2e6f03-7af5-4a5b-bde8-25c030021ef5"
      data-name="DMH-Logo"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1143.96 274.53"
      {...props}
    >
      <g>
        <path
          style={{ fill: "currentColor" }}
          d="M0,257.3h1000L955.4,226c-8.2-5.8-203.6-142.2-367-183.1l-1.7-0.4c-89-22.3-215.1-45.9-281.8,6.1
		c-27.3,21.3-41.1,52.6-41.1,93v17.2h34.4v-17.2c0-29.7,9.1-51.2,27.9-65.9C387.2,28,531.1,64,578.3,75.8l1.7,0.4
		C690.4,103.9,820,179.7,888.2,222.9H0V257.3z"
        />
      </g>
    </svg>
  )
}
