import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import MdhMaus from "../components/layout/hdm-maus"

import "./index.scss"

export default function Home() {
  return (
    <StaticQuery
      query={graphql`
        query IndexQuery {
          site {
            siteMetadata {
              title
              description
              keywords
            }
          }
        }
      `}
      render={data => {
        let { title, description, keywords } = data.site.siteMetadata
        return (
          <Layout>
            <Helmet
              title={title}
              meta={[
                { name: "description", content: description },
                { name: "keywords", content: keywords },
              ]}
              defer={false}
            >
              <html lang="de" />
            </Helmet>

            <div className="container home">
              <p>
                Die DMH ist eine private Beteiligungsgesellschaft und investiert
                in erneuerbare Energien und nachhaltige soziale Projekte.
                <br />
                Die Gesellschaft wurde im Jahre 2017 gegründet.
              </p>
            </div>

            <MdhMaus className="mdh-maus" />
          </Layout>
        )
      }}
    />
  )
}
